<script>
import axios from "axios";
import Layout from "../../layouts/auth";
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import { mapState } from "vuex";

import appConfig from "@/app.config";
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "admin_Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      app_URL: process.env.VUE_APP_URL,
    };
  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      email: helpers.withMessage("Please enter valid email", email),
    },
    password: {
      required: helpers.withMessage("Password is required", required),
    },
  },
  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        axios.post(this.app_URL + "api/adminLogin", {
          email: this.email,
          password: this.password,
        }).then((res) => {
          localStorage.setItem('email', JSON.stringify(encodeURIComponent(btoa(res.data.email))));
          localStorage.setItem('userType', JSON.stringify(encodeURIComponent(btoa(res.data.userType))));
          localStorage.setItem('authenticatedUserId', JSON.stringify(encodeURIComponent(btoa(res.data.id))));
          this.$router.push({ path: '/' });
          // console.log(res);
        }).catch((error) => {
          if (!error.response) {
            // network error
            this.tryingToLogIn = false;
            this.authError = 'Error: Network Error';
            this.isAuthError = true;
            setTimeout(() => {
              this.isAuthError = false;
              this.authError = "";
            }, 2500)
          } else {
            this.tryingToLogIn = false;
            this.authError = error.response.data.message;
            this.isAuthError = true;
            setTimeout(() => {
              this.isAuthError = false;
              this.authError = "";
            }, 2500)
          }
        });
      }
    },
  },
  mounted() { },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body">
            <div class="text-center py-3"><img src="@/assets/images/citizenship/whinchester.png" alt="whinchester Logo"
                height="60"></div>
            <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
            <div v-if="notification.message" :class="'alert ' + notification.type">
              {{ notification.message }}
            </div>

            <b-form class="p-2">
              <b-form-group class="mb-3" id="input-group-1" label="Email" label-for="input-1">
                <b-form-input id="input-1" v-model="email" type="text" placeholder="Enter email" :class="{
                  'is-invalid': submitted && v$.email.$error,
                }"></b-form-input>
                <div v-for="(item, index) in v$.email.$errors" :key="index" class="invalid-feedback">
                  <span v-if="item.$message">{{ item.$message }}</span>
                </div>
              </b-form-group>

              <b-form-group class="mb-3" id="input-group-2" label="Password" label-for="input-2">
                <b-form-input id="input-2" v-model="password" type="password" placeholder="Enter password" :class="{
                  'is-invalid': submitted && v$.password.$error,
                }"></b-form-input>
                <div v-if="submitted && v$.password.$error" class="invalid-feedback">
                  <span v-if="v$.password.required.$message">{{
                    v$.password.required.$message
                  }}</span>
                </div>
              </b-form-group>
              <b-form-checkbox class="form-check me-2 mt-0" id="customControlInline" name="checkbox-1" value="accepted"
                unchecked-value="not_accepted">Remember me
              </b-form-checkbox>
              <div class="mt-3 d-grid">
                <b-button class="btn btn-theme w-100" @click.prevent="tryToLogIn">Log In</b-button>
                <p style="font-size:14px;margin-top:10px;text-align:center;">
                  <router-link to="/forgot-password" class="text-muted">
                     <p style="font-size:14px;"><i class="mdi mdi-lock me-1"></i>Forgot Password?</p>
                  </router-link>
                  <!-- <a href="/forgot-password"
                    style="color:#000;">Forgot Password?</a> -->
                </p>
              </div>
              <!-- <div class="mt-4 text-center">
                <router-link to="/applicant-login" class="text-muted">
                  <p style="font-size:14px;margin-top:10px;text-align:center;">Applicant Login?</p>
                </router-link>
              </div> -->
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <!-- <p>
            Don't have an account ?
            <router-link to="/register" class="fw-medium text-primary"
              >Signup now</router-link
            >
          </p> -->
          <p>
            © {{ new Date().getFullYear() }} whinchester. Crafted by MISPL
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>